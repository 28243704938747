.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.exchange-page .info-item {
  font-size: 0.32rem;
  color: #333;
  background: #fff;
  width: 100%;
  height: 1rem;
  padding: 0 0.24rem;
  margin-top: 0.2rem;
}
.exchange-page .info-item input::-webkit-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.exchange-page .info-item input::-moz-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.exchange-page .info-item input:-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.exchange-page .info-item input::-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.exchange-page .info-item input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.exchange-page .info-item .send-btn,
.exchange-page .info-item .countdown {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.2rem;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.exchange-page .info-item .send-btn {
  background: var(--main-color);
}
.exchange-page .info-item .send-btn:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.exchange-page .info-item .countdown {
  background: #999;
}
.exchange-page .tip-txt {
  margin: 0.44rem 0 0 0.24rem;
  color: var(--main-color);
  font-size: 0.28rem;
}
.exchange-page .btn-con {
  margin-top: 1rem;
}
